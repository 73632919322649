.smo-header {
	padding: 1rem 0;
	&__brand {
		&__image {
			height: 60px;
			width: auto;
		}
	}
	&__heading {
		margin-left: 1rem;
	}
	&__usericon {
		margin-left: auto;
		color: #ccc;
	}
}

.smo-page {
	&__section {
		margin: 3rem auto;
		width: clamp(300px, 50vw, 1200px);
		justify-self: center;
		min-height: 42rem;
		&__add-btn {
			width: 100%;
			border-radius: 24px!important;
		}
	}
	.rooms-card,
	.building-card,
	.meeting-card {
		margin: 1rem;
	}
}

.loading-div {
	height: 100%;
	//min-height: 42rem;
	display: flex;
	justify-content: center;
	align-items: center;
	&__image {
		height: 10rem;
		animation: expand 1s infinite;
	}
}

@keyframes expand {
	50% {
		scale: 1.2;
	}
}
