.building-card {
	padding: 0.6rem;
	&__body {
		display: flex;
		place-content: space-between;
	}
	@media (max-width: 800px) {
		&__body {
			flex-direction: column;
		}
	}
}
