.meeting-room-list {
	&__list-item {
		margin: 0.6rem;
		box-shadow: 2px 2px 2px 2px #ddd;
		//border-radius: 1.5rem;
		padding: 0.3rem 0.6rem;
		.pf-c-simple-list__item-link {
			//border-radius: 1.5rem;
		}
		&:hover {
			scale: 1.03;
			transition-duration: 0.3s;
		}
	}
	&__list-item.selected {
		scale: 1.03;
		transition-duration: 0.3s;
	}
}
